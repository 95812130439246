<template>
    <dates />
</template>

<script lang="js">
import dates from './Dates.vue';
import heading from '../components/Heading.vue';

export default {
    components: {
        dates,
        heading
    }
}
</script>

<style scoped></style>